
import Vue from 'vue';
import { C360Header } from '@c360/ui';
import SettingsMenuC360 from '../components/toolbar/menus/settingsMenuC360.vue';
import utils from '../../util';
import analytics from '../../mixins/analytics';

export default Vue.extend({
  name: 'app-toolbar',
  props: ['title', 'hideWhenPrint'],
  mixins: [analytics],
  components: { C360Header, SettingsMenuC360 },
  data(): {
    componentKey: number;
  } {
    return {
      componentKey: 0,
    };
  },
  computed: {
    toolbarOffset(): number {
      if (utils.getEnvForProductLink(true) !== 'prod') {
        return 40;
      }
      return 0;
    },
  },
  methods: {
    async onPreRedirectCallback(item): any {
      switch (item.toLowerCase()) {
        case 'media planner':
          this.analyticTrack(this.trackValue.EVENT_GO_TO_MP);
          break;
        case 'instant io':
          this.analyticTrack(this.trackValue.EVENT_GO_TO_IO);
          break;
        case 'help center':
          this.analyticTrack(this.trackValue.EVENT_GO_TO_HC);
          break;
        case 'portal':
          this.analyticTrack(this.trackValue.EVENT_RETURN_TO);
          break;
      }
      return new Promise(function (resolve) {
        // small timeout to receive answer from mixpanel
        setTimeout(() => {
          resolve('');
        }, 500);
      });
    },
    updateComponentKey(): void {
      setTimeout(() => {
        this.componentKey = this.componentKey + 1;
      }, 100);
    },
    handleDrawerToggle(): void {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (window as any).adminApp.$emit('admin-drawer-toggled');
    },
    goHome(): void {
      const recentStr: string | null = localStorage.getItem(`${this.$store.getters.user.email}:recentPages`);
      if (!recentStr) {
        this.$router.push({ name: 'home', query: { recent: 'true' } });
      } else {
        const lastCampaign = JSON.parse(recentStr).find(page => (page.name = 'home' && page.query?.id));
        this.$router.push({ name: 'home', query: lastCampaign ? lastCampaign.query : { recent: 'true' } });
      }
    },
  },
});
